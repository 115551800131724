import React from 'react'
import './airvoice-privacy.components.less'

const AirvoicePrivacyComponent = () => {
    return (
        <div>
            <div className="container">
                <div className="wrapper_block__block airvoice-content">
                    <h1>Privacy Policy for AirVoice</h1>
                    <p>This Privacy Policy (hereinafter "Policy") shall apply to all information which CityAir Corp.
                        (919 North market street Suite 950, Wilmington, DE 18901) (hereinafter referred to as
                        the Operator) may receive from Internet users when they use the Services allocated in the
                        Internet at <a className="contact_main__a_mail" href="https://airvoice.io">airvoice.io</a>
                        &nbsp;and applications AirVoice. Use of the Operator's Service means the
                        unconditional consent of the User to this Policy and the terms of processing of his/her
                        personal information specified in it; in case of disagreement with these terms the User
                        must refrain from using the Service and refuse to accede to this Policy and the Consent to
                        the processing of personal data.
                    </p>
                    <h2>1. PERSONAL USER INFORMATION, WHICH IS RECEIVED AND PROCESSED BY CITYAIR LLC</h2>
                    <p class="sub">1.1. For the purposes of this Policy, "personal user information" means:</p>
                    <p class="sub1">1.1.1 Personal information that the User provides about himself/herself when
                        registering (creating an account) or while using the Service, including the User's
                        personal data.</p>
                    <p class="sub1">1.1.2 Personal information that the User provides about third parties during the use
                        of the Service, including personal data of third parties.</p>
                    <p class="sub1">1.1.3. Data that is automatically transmitted to the Service during its use by the software
                        installed on the User's device, including the IP-address, cookie information,
                        information about the User's browser (or other program through which the Service is accessed),
                        access time, the address of the requested page.</p>
                    <p class="sub1">1.1.4. Other information about the User, the collection and/or provision of which is defined
                        in the governing documents of the Service (if such documents exist).</p>
                    <p class="sub">1.2. This Policy applies only to the Service. The Operator does not control and bears no
                        responsibility for the websites of third parties to which the User can go through the
                        links accessible in the Service. Other personal information may be collected or requested
                        from the User, and other actions may be performed on such websites.</p>
                    <p class="sub">1.3. The Operator doesn't verify the reliability of the personal information provided
                        by Users and doesn't control their legal capacity. However the Operator assumes that the
                        User provides reliable and sufficient personal information required by the Service, keeps
                        this information up to date and has full legal capacity.</p>

                    <h2>2. PURPOSES OF COLLECTION AND PROCESSING OF USERS' PERSONAL INFORMATION</h2>
                    <p class="sub">2.1. The Operator collects, processes and stores only those personal data which are necessary
                        for providing the Service and rendering services (execution of agreements with the User),
                        in particular for processing the User's orders, purchasing the Operator's goods by the
                        User, performing the obligations undertaken by the Operator under the agreement with
                        the User. The Operator does not collect, process and store personal data which are not d
                        irectly related to or not required for the purposes of personal data processing mentioned
                        in the present Policy and/or in the relevant notifications sent to the User.</p>
                    <p class="sub">2.2. The Operator may use the User's personal information for the following purposes:</p>
                    <p class="sub1">2.2.1. Identification of a party under agreements and contractual relations with the Operator;</p>
                    <p class="sub1">2.2.2. Providing the user with a personalized Service;</p>
                    <p class="sub1">2.2.3. Communication with the User, including sending notices, requests and information
                        regarding the use of the Service, the provision of services, as well as processing
                        requests and applications from the User;</p>
                    <p class="sub1">2.2.4. Improving the quality of the Service, usability, development of new services;</p>
                    <p class="sub1">2.2.5. Statistical and other studies based on anonymised data;</p>
                    <p class="sub1">2.2.6. Providing information of a promotional nature that is restricted by the laws and
                        industry standards of the countries in which the Service are distributed.</p>

                    <h2>3.TERMS OF PROCESSING OF USER’S PERSONAL INFORMATION AND ITS TRANSFER TO THIRD PARTIES</h2>
                    <p class="sub">3.1. The Operator stores personal information of Users in accordance with the internal
                        regulations of the Service.</p>
                    <p class="sub">3.2. The User’s personal information is kept confidential, except when the User voluntarily
                        provides information about himself/herself for public access to an unlimited number
                        of parties.</p>
                    <p class="sub">3.3. The Operator has the right to transfer the User's personal information to third parties
                        in the following cases:</p>
                    <p class="sub1">3.3.1. The User has expressed his/her consent to the transfer of data to the Operator's partners
                        if such transfer is necessary as part of the User's use of a particular Service or to provide
                        a service to the User;</p>
                    <p class="sub1">3.3.2. The transfer is provided for by the United Kingdom or other applicable law in accordance
                        with the procedure established by law;</p>
                    <p class="sub1">3.3.3. Such transfer takes place within the framework of reorganization, sale or other transfer
                        of activities (in whole or in part) of the Operator, and the acquirer assumes all obligations
                        to comply with the terms of this Policy with regard to personal information received from the
                        Users;</p>
                    <p class="sub1">3.3.4. In order to enable protection of rights and legitimate interests of the Operator or third
                        parties in cases where the User violates the terms of the User Agreement (or other agreement,
                        similar in its subject matter).</p>
                    <h2>4.MEASURES TO PROTECT USERS PERSONAL INFORMATION</h2>

                    <p class="sub">4.1. The Operator takes necessary and sufficient organizational and technical measures to protect the User's personal information from unauthorized or accidental access, destruction, modification, blocking, copying, distribution, as well as from other unlawful actions of third parties with it. The operator takes the following organizational and technical measures:</p>
                    <ul>
                        <li>appointment from among the Operator's employees the persons responsible for the organization of processing and protection of personal data;</li>
                        <li>limiting the number of persons who have access to personal data, their familiarization with the requirements of federal legislation and regulatory documents of the Operator on processing and protection of personal data;</li>
                        <li>organization of tracking, storage and circulation of data storage devices;</li>
                        <li>identification of security threats to personal data integrity during these data processing, the formation of threat models based on identified security threats;</li>
                        <li>development of a personal data protection system based on a threat model;</li>
                        <li>verification of the readiness and effectiveness of the use of information security tools;</li>
                        <li>delimitation of user access to information resources and firmware for information processing;</li>
                        <li>organization of access control to the Company’s territory, protection of premises containing technical means of personal data processing;</li>
                        <li>encryption and security: technical and organizational security measures, including encryption and authentication tools, to securely store personal data. The Operator protects personal data by using secure networks and limiting the number of persons with special access rights to such systems. The Operator backs up personal data;</li>
                        <li>personal data collected or created (processed) as part of the operation of websites and applications by the Operator. Personal data is stored for as long as necessary to achieve the purposes for which it was collected, unless otherwise stipulated by law.</li>
                    </ul>
                    <h2>5. CHANGES TO THE PRIVACY POLICY. APPLICABLE LAW</h2>
                    <p class="sub">5.1. The Operator has the right to make changes to this Privacy Policy. When making changes in the current edition, the date of the last update must be indicated. The new version of the Policy comes into effect from the moment of its posting, unless otherwise provided by the new version of the Policy.</p>
                    <p class="sub">5.2. The law of the United Kingdom shall apply to this Policy and the relationship between the User and the Operator arising in connection with the application of the Privacy Policy.</p>

                    <p>This policy is effective as of 2022-10-27</p>
                    <h2>Contact Us</h2>

                    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate
                        to contact us at <a className="contact_main__a_mail" href="mailto:developer@cityair.ru"> hello@cityair.global</a>.
                    </p>
                </div>
            </div>
        </div>
    )
}

AirvoicePrivacyComponent.propTypes = {}

AirvoicePrivacyComponent.defaultProps = {}

export default AirvoicePrivacyComponent
